@import url(https://fonts.googleapis.com/css2?family=Open+Sans:wght@300&display=swap);
.required:after {
    color: #e32;
    content: ' *';
    display: inline;
  }

.dateFilterRadio {
    width: 100%;
    display: flex !important;
  }

.dateFilterRadio > .ant-radio-button-wrapper {
    flex-grow: 1;
    display: flex;
    justify-content: center;
}

.multiSelectFilter {
  width: 100%;
  margin: 0 !important;
}

.multiSelectFilter > div > button {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.ant-steps-item-description {
  min-width: 400px;
}

.popover-custom-overlay .ant-popover-content .ant-popover-inner{
    border-radius: 5px !important
}

.popover-custom-overlay .ant-popover-content .ant-popover-inner .ant-popover-inner-content {
    padding: 8px !important;
}

.charts-container > canvas {
    width: 100%;
    height: 100%;
}

body {
    margin: 0px;
}

html {
    box-sizing: border-box;
}

*, *:before, *:after {
    box-sizing: inherit;
}

.required:after {
    content:" *";
    color: red;
}

/* .ant-popover-placement-leftTop .ant-popover-inner{
    box-shadow: none !important;
} */

.ant-popover{
    /* z-index: 2 !important; */
    padding: 10px;
}

/* .ant-popover-inner-content {
    padding: 0 !important;
} */

/* .dashboardTabs .ant-tabs-nav {
    margin: 0px !important;
    height: 30px;
} */

.ant-drawer{
    padding-top: 56px;
}

.ant-drawer-content-wrapper{
    height: calc(100vh - 56px) !important;
}

html {
    overflow: scroll;
    overflow-x: hidden;
}
::-webkit-scrollbar {
    width: 0;  /* Remove scrollbar space */
    background: transparent;  /* Optional: just make scrollbar invisible */
    display: none;
}

iframe {
    display: none;
}

html, body {
    overscroll-behavior-x: none;
}
