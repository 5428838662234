.required:after {
    color: #e32;
    content: ' *';
    display: inline;
  }

.dateFilterRadio {
    width: 100%;
    display: flex !important;
  }

.dateFilterRadio > .ant-radio-button-wrapper {
    flex-grow: 1;
    display: flex;
    justify-content: center;
}

.multiSelectFilter {
  width: 100%;
  margin: 0 !important;
}

.multiSelectFilter > div > button {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.ant-steps-item-description {
  min-width: 400px;
}
